import React from 'react';
import Meta from "../../image/CERTIFICATE_LANDING_PAGE~GNFRPR5QHT4W.jpeg";
import Udemy from "../../image/UDEMY REACT.jpg";
import Nextjs from "../../image/nextjs.jpg";
import Git from "../../image/Git and Github.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/effect-coverflow";

import { EffectCoverflow } from 'swiper/modules';
import "./Accomplishments.css";

const slides = [
  { image: Meta, title: "Meta Certification" },
  { image: Udemy, title: "Udemy React Certification" },
  { image: Git, title: "Git and Github"},
  { image: Nextjs, title: "Nextjs"}
];

export default function Accomplishments() {
  return (
    <section id='accomplishments' className="py-10">
      <h2 className="text-2xl font-bold mb-6 text-center">Accomplishments</h2>
      <div className="main-swiper">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          modules={[EffectCoverflow]}
          className="mySwiper"
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <img src={slide.image} alt={slide.title} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
