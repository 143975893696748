import React, { useState } from "react";

function TicTacToe() {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const winner = calculateWinner(board);

  const handleClick = (index) => {
    if (board[index] || winner) return;

    const newBoard = board.slice();
    newBoard[index] = isXNext ? "X" : "O";
    setBoard(newBoard);
    setIsXNext(!isXNext);
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
  };

  return (
    <div className="flex flex-col items-center p-4 space-y-4 bg-white rounded-lg">
      <h1 className="text-2xl font-semibold">Tic-Tac-Toe</h1>
      <div className="grid grid-cols-3 gap-2">
        {board.map((value, index) => (
          <Square key={index} value={value} onClick={() => handleClick(index)} />
        ))}
      </div>
      <div className="flex flex-col items-center space-y-2">
        {winner ? (
          <h2 className="text-lg font-bold text-green-500">Winner: {winner}</h2>
        ) : (
          <h2 className="text-lg">Next Player: {isXNext ? "X" : "O"}</h2>
        )}
        <button
          onClick={resetGame}
          className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
        >
          Reset Game
        </button>
      </div>
    </div>
  );
}

function Square({ value, onClick }) {
  return (
    <button
      className="w-16 h-16 text-2xl font-bold border border-gray-400 rounded-lg flex items-center justify-center hover:bg-gray-200"
      onClick={onClick}
    >
      {value}
    </button>
  );
}

function calculateWinner(board) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  for (let line of lines) {
    const [a, b, c] = line;
    if (board[a] && board[a] === board[b] && board[a] === board[c]) {
      return board[a];
    }
  }

  return null;
}

export default TicTacToe;
