// animations.js
export const bgAnimate = {
    hidden: {
      clipPath: "polygon(21% 26%, 77% 26%, 77% 77%, 21% 77%)",
    },
    show: {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      transition: {
        ease: "easeInOut",
        duration: 0.8,
        delay: 1,
      },
    },
  };
  
  export const textAnimate1 = {
    hidden: { y: "100%", opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
        staggerChildren: 0.4,
        delayChildren: 1,
      },
    },
  };
  
  export const textAnimate2 = {
    hidden: { x: 0 },
    show: (i) => ({
      x: i,
      transition: { ease: "easeOut", duration: 0.8 },
    }),
  };
  