import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";


export default function Footer() {
    return (
      <footer>
          <div className="footer-social">
            <div className="social-icon">
              <a
                href="https://www.instagram.com/tsak.__/"
               
              >
                <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '1.5rem' }} />
              </a>
            </div>
            <div className="diagonal-line"></div>
            <div className="social-icon">
              <a
                href="https://www.facebook.com/nicktsiakiris/"
                whilehover={{ scale: 1.2, color: '#4267B2' }}
                transition={{ duration: 0.3 }}
              >
                <FontAwesomeIcon icon={faFacebook} style={{ fontSize: '1.5rem' }} />
              </a>
            </div>
            <div className="diagonal-line"></div>
            <div className="social-icon">
              <a
                href="https://github.com/ntsiakiris"
                whilehover={{ scale: 1.2, color: '#333' }}
                transition={{ duration: 0.3 }}
              >
                <FontAwesomeIcon icon={faGithub} style={{ fontSize: '1.5rem' }} />
              </a>
            </div>
            <div className="diagonal-line"></div>
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/nikolaos-tsiakiris-b047a2229/"
                whilehover={{ scale: 1.2, color: '#0077B5' }}
                transition={{ duration: 0.3 }}
              >
                <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '1.5rem' }} />
              </a>
            </div>
          </div>
        <p>&copy; 2024 My Portfolio. All rights reserved</p>
      </footer>
    );
  }