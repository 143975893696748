import { useEffect, useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import TechIcons from "./TechIcons.jsx";
import { motion } from "framer-motion";
import { bgAnimate, textAnimate1, textAnimate2 } from "../animations/aboutAnimations.js";
import ProfileImage from "../image/portfolio-black-removebg-preview.png";
import { faMapMarkerAlt, faSchool, faBook, faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { useWindowSize } from 'react-use';


export default function About() {
  const { width } = useWindowSize();
  const [text] = useTypewriter({
    words: ["Front-End Developer!", "Creative Coder!"],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });

  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 4200);

    return () => clearTimeout(timer);
  }, []);

  
  const isMobile = width < 550;

  return (
    <section id="about" className="relative h-fit overflow-hidden">
      {startAnimation && (
        <>
          <motion.div
            className="absolute ml-[80px] bottom-12 left-0 w-1/3 h-full z-0 max-w-[500px] lgl:ml-[calc(20%-30px)] max-md:bottom-20 max-customsml:hidden"
            style={{
              backgroundImage: `url(${ProfileImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left center",
            }}
            variants={bgAnimate}
            initial="hidden"
            animate="show"
          />

          <div className=" relative mt-20 z-10 flex flex-col justify-end align-center w-full max-w-5xl px-8 gap-8 md:flex-row ml-[calc(20%-20px)] meg:ml-[calc(30%-20px)] max-lgl:ml-[calc(30%-20px)] max-customsml:mt-8">
            <motion.div
              className="w-auto text-right lgl:ml-[calc(30%-20px)]"
              variants={textAnimate1}
              initial="hidden"
              animate="show"
            >
              <motion.h1
                className=" mt-20 text-2xl font-bold text-white w-[400px] ml-[calc(20%-20px)]  mdl:text-4xl   max-lg:ml-[calc(15%-20px)] max-customsml:ml-0"
                variants={textAnimate2}
                custom={isMobile ? -180 : -100} 
              >
                Hi, I'm Tsiakiris Nikos, <br /> a {text}
              </motion.h1>

              <motion.p
                className="text-center w-auto mt-12 overflow-hidden mr-[calc(50%-20px)] text-md text-gray-200 leading-relaxed xl:text-lg xl:mr-20 lgl:mr-[250px]  lgl:w-[500px] meg:w-[600px] max-customsml:[grid left-1/2] max-customsml:[mr-0]  max-customsml:w-auto"
                variants={textAnimate2}
                custom={isMobile ? -20 : 100} 
              >
                <FontAwesomeIcon icon={faMapMarkerAlt} className=" text-gray-200" />
                &nbsp; I come from the heroic city Naousa, in the prefecture of Imathia.  
                <br />
                <br />
                <FontAwesomeIcon icon={faSchool} className="text-gray-200" />
                &nbsp; I graduated in 2022 with an MSc in Information and Electronic Engineering
                from the International Hellenic University, Thessaloniki (Sindos campus).
                <br />
                <br />
                <FontAwesomeIcon icon={faBook} className="text-gray-200" />
                &nbsp; My technical stack includes JavaScript, Java and SQL.
                I have experience with React and Next.js,
                along with knowledge of backend databases like MongoDB. I enjoy creating seamless user interfaces, 
                focusing on performance and accessibility.
                <br />
                <br />
                <FontAwesomeIcon icon={faDumbbell} />
                &nbsp; My hobbies include sports such as basketball and MMA, and I have a passion for working out at the gym.
                These activities help me stay active and maintain a balanced lifestyle, which fuels my energy and focus in my professional work.
                <br />
                <div className="mt-8 flex gap-x-4 items-center justify-center max-customsml:ml-0">
                <a href="https://github.com/ntsiakiris" className="text-gray-200 hover:text-teal-300">
                  <FontAwesomeIcon icon={faGithub} size="2x" />
                </a>
                <a href="https://www.linkedin.com/in/nikolaos-tsiakiris-b047a2229/" className="text-gray-200 hover:text-teal-300">
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
                </div>
              </motion.p>

             
            </motion.div>
          </div>

          <div className="relative pt-12 mt-32 bottom-8 left-1/2 transform -translate-x-1/2">
            <TechIcons />
          </div>
        </>
      )}
    </section>
  );
}
