import React, { useState } from 'react';
import Education from './Education';
import Skills from './Skills';
import Accomplishments from './Accomplishments';

export default function Resume() {
  const [activeTab, setActiveTab] = useState('education');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section id="resume" className="resume-section">
      <div className="resume-header">
        <h2>My Resume</h2>
      </div>
      <div className="resume-tabs">
        <ul className="resume-list">
          <li
            onClick={() => handleTabChange('education')}
            className={`resume-item ${activeTab === 'education' ? 'active' : ''}`}
            role="tab"
            aria-selected={activeTab === 'education'}
          >
            <span className="resume-link">Education & Job Experience</span>
          </li>
          <li
            onClick={() => handleTabChange('skills')}
            className={`resume-item ${activeTab === 'skills' ? 'active' : ''}`}
            role="tab"
            aria-selected={activeTab === 'skills'}
          >
            <span className="resume-link">Professional Skills</span>
          </li>
          <li
            onClick={() => handleTabChange('accomplishments')}
            className={`resume-item ${activeTab === 'accomplishments' ? 'active' : ''}`}
            role="tab"
            aria-selected={activeTab === 'accomplishments'}
          >
            <span className="resume-link">Achievements</span>
          </li>
        </ul>
      </div>
      <div className="resume-content">
        {activeTab === 'education' && <Education />}
        {activeTab === 'skills' && <Skills />}
        {activeTab === 'accomplishments' && <Accomplishments />}
      </div>
    </section>
  );
}
