import React, { useState } from "react";
import { motion } from "framer-motion";
import  Rock  from "../image/Rock.png";

function RockPaperScissors() {
  const [playerChoice, setPlayerChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);
  const [result, setResult] = useState(null);

  // Randomly choose the computer's move
  const choices = ["rock", "paper", "scissors"];

  const handlePlayerChoice = (choice) => {
    const computerMove = choices[Math.floor(Math.random() * 3)];
    setPlayerChoice(choice);
    setComputerChoice(computerMove);
    determineWinner(choice, computerMove);
  };

  const determineWinner = (player, computer) => {
    if (player === computer) {
      setResult("It's a tie!");
    } else if (
      (player === "rock" && computer === "scissors") ||
      (player === "paper" && computer === "rock") ||
      (player === "scissors" && computer === "paper")
    ) {
      setResult("You Win!");
    } else {
      setResult("Computer Wins!");
    }
  };

  const resetGame = () => {
    setPlayerChoice(null);
    setComputerChoice(null);
    setResult(null);
  };

  return (
    <div className="flex justify-center items-center flex-col p-4">
      <h1 className="text-2xl font-bold mb-4">Rock Paper Scissors</h1>

      <div className="flex justify-center space-x-8 mb-4">
        {["rock", "paper", "scissors"].map((choice) => (
          <motion.button
            key={choice}
            onClick={() => handlePlayerChoice(choice)}
            className="bg-blue-500 text-white p-4 rounded-full hover:bg-blue-600 transition"
            whilehover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <span role="img" aria-label={choice} className="text-3xl">
              {choice === "rock" ? (
                <img src={Rock} alt="rock" className="w-12 h-12" />
              ) : choice === "paper" ? (
                "📄"
              ) : (
                "✂️"
              )}
            </span>
          </motion.button>
        ))}
      </div>

      <div className="flex justify-center space-x-8 mb-6">
        {playerChoice && (
          <div className="text-xl">
            <h2 className="font-semibold">Your choice:</h2>
            <motion.div
              key={playerChoice}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <span role="img" aria-label={playerChoice} className="text-5xl">
                {playerChoice === "rock" ? (
                  <img src={Rock} alt="rock" className="w-16 h-16" />
                ) : playerChoice === "paper" ? (
                  "📄"
                ) : (
                  "✂️"
                )}
              </span>
            </motion.div>
          </div>
        )}

        {computerChoice && (
          <div className="text-xl">
            <h2 className="font-semibold">Computer's choice:</h2>
            <motion.div
              key={computerChoice}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <span role="img" aria-label={computerChoice} className="text-5xl">
                {computerChoice === "rock" ? (
                  <img src={Rock} alt="rock" className="w-16 h-16" />
                ) : computerChoice === "paper" ? (
                  "📄"
                ) : (
                  "✂️"
                )}
              </span>
            </motion.div>
          </div>
        )}
      </div>

      {result && (
        <motion.div
          className="text-3xl font-bold mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {result}
        </motion.div>
      )}

      <button
        onClick={resetGame}
        className="mt-4 bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
      >
        Play Again
      </button>
    </div>
  );
}

export default RockPaperScissors;
