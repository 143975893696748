import React, { useEffect } from "react";
import { preLoaderAnim } from "../animations/animation.js";
import "./preloader.css";
import gif from "../image/345.gif";

const Preloader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);

  return (
    <div className="preloader">
        <div className="gif-container">
        <img src={gif} alt="loading gif" />
      </div>
         <div className="texts-container">
        <span>Developer</span>
        <span>Engineer</span>
        <span>Creator</span>
      </div>
    </div>
  );
};

export default Preloader;
