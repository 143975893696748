import React from "react";

function BackButton({ onClick, className = "", label = "Back" }) {
    return (
        <button
            onClick={onClick}
            className={`absolute bottom-4  px-3 py-1 bg-gray-500 text-white rounded hover:bg-blue-700 ${className}`}
        >
            {label}
        </button>
    );
}

export default BackButton;
