import "./background.css";
import video from "../../image/2297636-uhd_3840_2160_30fps.mp4";
import fallbackImage from "../../image/fallbackbgimage.jpg";

export default function Background () {
  return (
    <>
      <div className="shadow-overlay"></div>
      <video
        playsInline
        autoPlay
        muted
        loop
        preload="auto"
        id="bg"
        poster={fallbackImage}
      >
        <source src={video} type="video/mp4" />
      </video>
    </>
  );
};

