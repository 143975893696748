import React from "react";
import { motion, useScroll } from 'framer-motion';
import { useRef } from 'react';
import ResumeCard from "./ResumeCard";


export default function Education ()  {

  const ref= useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref ,
    offset:["0 1", "0.6 1"]
  });


  return (
    <motion.div
      className="education-container"
      ref={ref}
      style={{scale: scrollYProgress,
      opacity: scrollYProgress
      }}
    >
      <div className="education-section">
        <div className="education-header">
          <p className="education-date">2013 - 2022</p>
          <h2 className="education-title">Education</h2>
        </div>
        <div className="education-cards">
          <ResumeCard
            title="MSc in Information and Electronic Engineering"
            subTitle="International Hellenic University (2016 - 2022)"
            des="The training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
          />
          <ResumeCard
            title="Secondary School Education"
            subTitle="Secondary School (2010 - 2016)"
            des="Secondary school education, for ages 12 to 18, includes lower secondary (middle school) and upper secondary (high school). Students study a broad range of subjects initially, then specialize in upper secondary, preparing for higher education or careers. It often ends with key exams. "
          />
        </div>
      </div>
      <div className="education-section">
        <div className="education-header">
          <p className="education-date">2023 - 2024</p>
          <h2 className="education-title">Job Experience</h2>
        </div>
        <div className="education-cards">
          <ResumeCard
            title="Research & Informatics Technology Office"
            subTitle="Greek Army, Research & Informatics Technology Office (May 2023- March 2024)"
            des="During my compulsory military service as soldier of the Research and Technology Office worked as an IT Support Specialist and Systems Administrator."
          />
        </div>
      </div>
    </motion.div>
  );
};


