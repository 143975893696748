import gsap from "gsap";

// Declare a general timeline to use in all the animation functions.
const tl = gsap.timeline();

// Function to disable scrolling
const disableScroll = () => {
  document.body.style.overflow = 'hidden';
  document.documentElement.style.overflow = 'hidden';
};

// Function to enable scrolling
const enableScroll = () => {
  document.body.style.overflow = '';
  document.documentElement.style.overflow = '';
};

// Preloader Animation
export const preLoaderAnim = () => {
  
  disableScroll();

  tl.to(".texts-container", {
    duration: 0,
    opacity: 1,
    ease: "Power3.easeOut",
  })
    .from(".texts-container span", {
      duration: 1.5,
      delay: 1,
      y: 70,
      skewY: 10,
      stagger: 0.4,
      ease: "Power3.easeOut",
    })
    .to(".texts-container span", {
      duration: 1,
      y: 70,
      skewY: -20,
      stagger: 0.2,
      ease: "Power3.easeOut",
    })
    .to(".preloader", {
      duration: 0.5,
      height: "0vh",
      ease: "Power3.easeOut",
      onComplete: () => {
         enableScroll();
        gsap.set(".preloader", { display: "none" });
      },
    });
};