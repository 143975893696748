import Phone from "./Phone.jsx";
import { useRef, useState } from 'react';
import emailjs from 'emailjs-com'; 
import { motion, useScroll, useTransform } from 'framer-motion';

export default function Contact() {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const contactTextRef = useRef(null); 
  const { scrollYProgress } = useScroll({
    target: contactTextRef,
    offset: ["0.1 1", "0.7 1"] 
  });
  
  const textOpacity = useTransform(scrollYProgress, [0, 0.2], [0, 1]);  
  const formOpacity = useTransform(scrollYProgress, [0.6, 0.7], [0, 1]);  



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const form = formRef.current;
       const result = await emailjs.sendForm('service_dxnt43f', 'template_oxyzlza', form, 'I-JQ3QNYTWeGxbdAz');

      console.log(result.text);
      alert('Form submitted successfully!');
      form.reset(); 
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to submit the form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="contact" className="relative py-[120px] px-[40px] gap-16">
  <motion.div
    className=" text-center text-white"
    ref={contactTextRef}
    style={{
      opacity: textOpacity,
      transition: "opacity 0.5s ease-in-out",
      top: "120px",
    }}
  >
    <h2 className="text-[2.25rem] font-bold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-teal-200 mb-5 font-montserrat">
      Contact Me
    </h2>
  </motion.div>

    <motion.div
     className="flex-1 flex-wrap"
      ref={contactTextRef}
      style={{
        opacity: formOpacity,
        transition: "opacity 0.8s ease-in-out",
      }}
    >
      <div className="flex justify-center align-center items-center gap-8 max-mdl:flex-col-reverse">
      <div className="ml-24 max-mdl:ml-0">
        <Phone />
      </div>
      <div className="flex gap-16 [width:calc(50%-2rem)] max-md:w-[70%]  items-center">
        <div className=" bg-gray-400/30 p-5 mx-auto rounded-md shadow-md w-full max-w-[900px]">
          <form ref={formRef} onSubmit={handleSubmit}>
            <label htmlFor="name" className="block mb-2 font-bold text-white">
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="w-full p-2 mb-5 rounded-md bg-white/50 text-black focus:outline-none focus:ring focus:ring-black"
            />
            <label htmlFor="email" className="block mb-2 font-bold text-white">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full p-2 mb-5 rounded-md bg-white/50 text-black focus:outline-none focus:ring focus:ring-black"
            />
            <label htmlFor="message" className="block mb-2 font-bold text-white">
              Message:
            </label>
            <textarea
              id="message"
              name="message"
              required
              className="w-full p-2 mb-5 rounded-md bg-white/50 text-black focus:outline-none focus:ring focus:ring-black"
            />
            <input
              type="submit"
              value={loading ? "Sending data..." : "Submit"}
              disabled={loading}
              className="bg-gray-800 text-white py-2 px-4 rounded-md cursor-pointer hover:bg-gray-700"
            />
            {error && <p className="error-message text-red-500">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  </motion.div>
</section>

  );
}
